import React from 'react'

export const MyConcierge = () => {
    return (
        <div>
            MyConcierge Page content
        </div>
    )
}

export default MyConcierge
