export const Logo = ({ className = '' }) => {
  return (
    <>
      <h1 className={`text-2xl lg:text-3xl text-teal-700 brand-font m-0 ${className}`}>Coffee Web Studio</h1>
      <small className={`text-xs text-teal-700 block -mt-2 ${className}`}>EXCLUSIVE CONCIERGE SERVICE</small>
    </>
  )
}

export default Logo
