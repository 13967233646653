import { gql } from "@apollo/client";

export const GET_PAGE_TRANSPORTATION = (locale) => gql`
query getTransportPage {
  pageTransportation(locale: ${locale}) {
    bannerImage {
      alt
      title
      url
    }
    pageTitle
    description
  }
}
`;
