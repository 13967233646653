export const ES_TEXTS = {
  GetInTouch: "Contáctanos",
  ContactLegend: "Si tienes alguda duda o petición especial, nos puedes comentar llenando el formulario y te contactaremos a la brevedad.",
  ContactPostalAddress: "Puedes encontrarnos en el Aeropuerto Internacional de Cancún.",
  PhoneNumber: 'Teléfono',
  Email: 'Correo',
  YourName: 'Tu nombre',
  Message: 'Mensaje',
  Submit: 'Enviar',
  MenuHome: 'Inicio',
  MenuTransport: 'Traslados',
  MenuYachts: 'Yates',
  MenuTours: 'Tours',
  MenuVillas: 'Villas',
  MenuClubs: 'Clubes',
  MenuEvents: 'Eventos',
  MenuRestaurants: 'Restaurantes',
}
