import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import ImageCard from '../../../components/imageCard.jsx/index.jsx'
import Loading from '../../../components/loading/index.jsx'
// import ImageCard from '../../components/imageCard.jsx/index.jsx'
// import Loading from '../../components/loading/index.jsx'
import GET_ADRI_SERVICES from './GET_ADRI_SERVICES.js'
// import ServiceList from './serviceList.jsx'

export const MenuAdri = () => {
    const { loading, error, data } = useQuery(GET_ADRI_SERVICES)
    const [page, setPage] = useState()

    useEffect(() => {
        setPage(data?.allMenuServiciosAdris)
    }, [data])

    if (loading) return <Loading />
    if (error) return <p>Error loading.</p>;
    console.log('allMenuServiciosAdris', page);

    return (
        <section className="grid mb-6">
            <header className="text-2xl text-center sticky top-0 bg-white bg-opacity-80 flex-grow py-2 border-b-2 border-orange-100">
                Servicios Adri
            </header>

            <section className="flex flex-wrap gap-4 mx-auto max-w-6xl lg:mt-10">
                {page?.map((service) => <ImageCard
                    bgColor={service.imagen?.colors[0]?.hex}
                    textColor={service.imagen?.colors[0]?.hex}
                    key={service.id}
                    image={service.imagen?.url}
                    title={service.servicio}
                />)}
            </section>
        </section>
    )
}

export default MenuAdri
