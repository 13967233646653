import ImageGallery from "./imageGallery.jsx"

export const Card = ({ title, image, gallery, text, children, flat = false }) => {

  return (<article className={`flex flex-col rounded-md ${!flat ? 'shadow-md border' : ''}`}>
    <header className="flex-col text-base text-center py-2 text-gray-700">
      {title}
    </header>
    <div className="flex flex-col">
      {gallery &&
        <aside className="flex-auto max-w-2xl px-2">
          <ImageGallery images={gallery} />
        </aside>
      }
      {image &&
        <aside className="flex-auto max-w-2xl px-2">
          <img src={image} className="w-auto rounded-md" alt="sample" />
        </aside>
      }
      <aside className={`flex-auto text-gray-400 ${!text ? 'hidden' : ''}`}>
        <p className="px-4 py-2 text-gray-600">{text}</p>
        {/* <span onClick={() => handleAdd(id)} className="px-4 py-1 my-2 mx-auto block w-1/2 text-white cursor-pointer text-center bg-blue-500 hover:bg-blue-600 rounded-xl"> Add to list </span> */}
        {/* <span onClick={() => handleRemove(id)} className="px-4 py-1 my-2 mx-auto block w-1/2 text-red-600 underline cursor-pointer text-center hover:bg-orange-50 rounded-xl"> Remove from list </span> */}
      </aside>
      <div className="">{children}</div>
    </div>
  </article>)
}
export default Card
