import React from 'react'
import VideoBanner from '../../components/video/VideoBanner.jsx'

import imgClubs from '../../images/sections/clubs.jpg'
import imgEvents from '../../images/sections/events.jpg'
import imgRestaurants from '../../images/sections/restaurants.jpg'
// import imgReviews from '../../images/sections/reviews.jpg'
import imgTours from '../../images/sections/tours.jpg'
import imgTransport from '../../images/sections/transportation.jpg'
import imgVillas from '../../images/sections/villas.jpg'
import imgYacht from '../../images/sections/yacht.jpg'
import Pages from '../index.jsx'
import ServiceCircle from './services/ServiceCircle.jsx'


export const HomePage = () => {

  return (
    <div>
      <VideoBanner src="assets/intro-video.mov" />
      <div className="md:absolute bg-teal-600 border-solid pl-4 py-2 text-lg md:text-2xl md:-mt-14 md:bg-opacity-30  w-full text-white font-bold tracking-wider">
        Experience the Mexican Caribbean.
      </div>

      {/* <section className="border gap-3 px-4 lg:p-0 grid grid-cols-7 mx-auto lg:max-w-7xl my-8"> */}

      <section className="lg:px-0 px-2 gap-1 mx-auto md:max-w-6xl md:gap-x-2 my-8 md:my-14 flex flex-row items-start">
        <ServiceCircle img={imgClubs} url={Pages.Clubs} imgAlt="Day &amp; Night" />
        <ServiceCircle img={imgEvents} url={Pages.Events} imgAlt="Special events" />
        <ServiceCircle img={imgRestaurants} url={Pages.Restaurants} imgAlt="Restaurants" />
        <ServiceCircle img={imgTours} url={Pages.Tours} imgAlt="Tours" />
        <ServiceCircle img={imgTransport} url={Pages.Transportation} imgAlt="Transportation" />
        <ServiceCircle img={imgVillas} url={Pages.Villas} imgAlt="Villas" />
        <ServiceCircle img={imgYacht} url={Pages.Yachts} imgAlt="Yachts" />
      </section>

    </div>
  )
}

export default HomePage
