import { gql } from "@apollo/client"

export const GET_ADRI_SERVICES = gql`
query getAdriServices {
  allMenuServiciosAdris {
    id
    servicio
    precio
    imagen {
      url
      colors {
        hex
      }
    }
  }
}

`
export default GET_ADRI_SERVICES
