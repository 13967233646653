export const ImageCard = ({ bgColor, title, image, text, children, className, flat = false }) => {
  const phoneNumber = "5219984956991"
  const message = "Hola%2C%20me%20gustar%C3%ADa%20información%20acerca%20del%20servicio%20de%20"
  const servicemessage = title?.replaceAll(' ', "%20")
  const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}${servicemessage}`

  return (<article className={`mx-auto flex pb-2 md:flex-1 ${className || ''} rounded-md ${!flat ? 'shadow-md border' : ''}`} style={{ backgroundColor: bgColor }}>
    <div className="">
      <h1 className="text-2xl py-2 text-center text-white" style={{ backgroundColor: bgColor }}><small>{title}</small></h1>
      {image &&
        <aside className="px-2">
          <img src={image} className="w-auto rounded-md" alt="sample" />
        </aside>
      }
      <div className="text-center text-lime-50">
        <a target="_blank" href={whatsappLink}>Contactar por Whatsapp</a>
      </div>
      <aside className={` invert ${!text ? 'hidden' : ''}`}>
        <p className="px-4 py-2">{text}</p>
        {/* <span onClick={() => handleAdd(id)} className="px-4 py-1 my-2 mx-auto block w-1/2 text-white cursor-pointer text-center bg-blue-500 hover:bg-blue-600 rounded-xl"> Add to list </span> */}
        {/* <span onClick={() => handleRemove(id)} className="px-4 py-1 my-2 mx-auto block w-1/2 text-red-600 underline cursor-pointer text-center hover:bg-orange-50 rounded-xl"> Remove from list </span> */}
      </aside>
      <div className="">{children}</div>
    </div>
  </article >)
}
export default ImageCard
