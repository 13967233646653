import { useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../common/appContext.jsx'
import Loading from '../../components/loading/index.jsx'
import { GET_PAGE_RESTAURANT } from '../../queries/pages/pageRestaurant.js'
import RestaurantList from './restaurantList.jsx'

export const RestaurantsPage = () => {
    const { setTitle, setServiceBanner, language } = useContext(AppContext)
    const { loading, error, data } = useQuery(GET_PAGE_RESTAURANT(language))
    const [page, setPage] = useState()

    useEffect(() => {
        setPage(data?.pageRestaurant)
    }, [data])

    useEffect(() => {
        if (page?.bannerImage) {
            setServiceBanner(page.bannerImage?.url)
        }
        if (page?.pageTitle) {
            setTitle(page?.pageTitle)
        }
    }, [page,setServiceBanner, setTitle])

    if (loading) return <Loading />
    if (error) return <p>Error loading.</p>;

    return (<>
        <div className="grid md:grid-cols-3 grid-cols-1s gap-8">
            <div className="col-span-3">
                <p className="py-4 md:w-1/2 w-4/5 mx-auto text-justify">
                    {page?.description}
                </p>
            </div>
        </div>

        <RestaurantList />
    </>)
}

export default RestaurantsPage
