import { useQuery } from "@apollo/client"
import { useContext } from "react"
import { AppContext } from "../../common/appContext.jsx"
import Card from '../../components/card'
import Loading from "../../components/loading/index.jsx"
import { GET_LIST_VEHICLES } from "../../queries/content/vehicles.js"

export const VehicleList = () => {
  const { language } = useContext(AppContext)
  const { loading, error, data } = useQuery(GET_LIST_VEHICLES(language))

  if (loading) return <Loading />
  if (error) return <div>We don't have available vehicles a this time.</div>


  return <div className="max-w-7xl mx-auto">
    <div className="grid md:grid-cols-4 grid-cols-1s gap-8">
      {(data?.allSectionTransports?.map((vehicle) =>
        <Card key={vehicle.id}
          title={vehicle.vehicleType}
          image={vehicle.image?.url}>
          <div className="px-4 py-3 text-gray-600 w-full">Pax: {vehicle.maxPax}</div>
        </Card>
      ))}
    </div>
  </div>
}
export default VehicleList
