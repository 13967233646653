import { useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../common/appContext.jsx'
import Heading from '../../components/heading'
import Loading from '../../components/loading/index.jsx'
import { GET_PAGE_YACHT } from '../../queries/pages/pageYacht.js'
import YachtList from './yachtList.jsx'

export const YachtPage = () => {
    const { setTitle, setServiceBanner, language } = useContext(AppContext)
    const { loading, error, data } = useQuery(GET_PAGE_YACHT(language))
    const [page, setPage] = useState()

    useEffect(() => {
        setPage(data?.pageYacht)
    }, [data])

    useEffect(() => {
        if (page?.bannerImage) {
            setServiceBanner(page.bannerImage?.url)
        }
        if (page?.pageTitle) {
            setTitle(page?.pageTitle)
        }
    }, [page])

    if (loading) return <Loading />
    if (error) return <p>Error loading.</p>;

    return (
        <>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-8">
                <div className="col-span-3">
                    <p className="py-4 md:w-1/2 w-4/5 mx-auto text-justify">
                        {page?.description}
                    </p>
                </div>
            </div>
            <Heading text="Explore the sea in our yachts" />
            <YachtList />
        </>
    )
}

export default YachtPage
