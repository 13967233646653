import { useContext } from "react"
import { Outlet } from "react-router-dom"
import { AppContext } from "../../common/appContext.jsx"
import Heading from "../../components/heading/index.jsx"
import './style.css'

export const ServiceLayout = () => {
  const { title, serviceBanner } = useContext(AppContext)

  return <div className="my-6">
    <div className="service-header-bg h-96" style={{
      backgroundImage: serviceBanner ? `url(${serviceBanner || ''})` : null,
    }}></div>
    <Heading text={title} />
    <div className="max-w-7xl mx-auto text-gray-600 font-light">
      <Outlet />
    </div>
  </div >
}

export default ServiceLayout
