import { useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../common/appContext.jsx'
import TransferList from './transferList.jsx'
import { GET_PAGE_TRANSPORTATION } from '../../queries/pages/pageTransportation.js'
import VehicleList from './vehicleList.jsx'
import Loading from '../../components/loading/index.jsx'
import Heading from '../../components/heading'


export const TransportationPage = () => {
    const { setTitle, setServiceBanner, language } = useContext(AppContext)
    const { loading, error, data } = useQuery(GET_PAGE_TRANSPORTATION(language))
    const [page, setPage] = useState()

    useEffect(() => {
        setPage(data?.pageTransportation)
    }, [data])

    useEffect(() => {
        if (page?.bannerImage) {
            setServiceBanner(page.bannerImage?.url)
        }
        if (page?.pageTitle) {
            setTitle(page?.pageTitle)
        }
    }, [page,, setServiceBanner, setTitle])

    if (loading) return <Loading />
    if (error) return <p>Error loading.</p>;

    return (
        <div className="grid gap-10">
            <div className="grid md:grid-cols-3 grid-cols-1s gap-8">
                <div className="col-span-3">
                    <p className="py-4 md:w-1/2 w-4/5 mx-auto text-justify">
                        {page?.description}
                    </p>
                </div>
                <TransferList />
            </div>

            <Heading text="Our fleet" />
            <VehicleList />
        </div>
    )
}

export default TransportationPage
