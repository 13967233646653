import { gql } from "@apollo/client";

export const GET_PAGE_TOUR = (locale) => gql`
query getTourPage {
  pageTour(locale: ${locale}) {
    bannerImage {
      alt
      title
      url
    }
    pageTitle
    description
  }
}
`;
